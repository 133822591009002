<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add Money
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Description"
            v-model="cash.description"
            id="description"
            label="Description:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="cash.type"
            id="type"
            :options="cashTypes"
            displayValue="description"
            placeholder="Money Type"
            color="gold"
            label="Select Type Of Money:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="cash.currency"
            id="currency"
            :options="currencies"
            displayValue="description"
            placeholder="Currency"
            color="gold"
            label="Select Currency:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <CurrencyField
            type="text"
            placeholder="0.00"
            id="amount"
            color="gold"
            v-model="cash.amount"
            :currencySymbol="cash.currency.symbol"
            label="Enter Amount:"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{parseFloat(Math.round((calculatedLocalAmount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="cash.amount < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="cashAssets.length > 0">
        <div class="col-12 bold smallheading my-4">
          Money
        </div>
      </div>
      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in cashAssets" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{displayDescription(asset.cashAssetType)}}
                </div>
                <div class="col-12 font12">
                  {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{displayDescription(asset.cashAssetType)}}
                </div>
                <div class="col-12 font12">
                  {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this cashAssets?" :message="cash.description" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddMoney',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      cash: {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      },
      isEdit: false,
      isDelete: false,
      currentKey: null,
      cashTypes: [
        {
          value: 'CASH_ON_HAND',
          description: 'Cash on Hand'
        }, {
          value: 'CASH_IN_SAFETY_DEPOSIT_BOX',
          description: 'Cash in Safety Deposit Box'
        }, {
          value: 'CASH_IN_BANK_CALL_ACCOUNT',
          description: 'Cash in Bank (Call Account)'
        }, {
          value: 'CASH_IN_BANK_CHEQUE_ACCOUNT',
          description: 'Cash in Bank (Cheque Account)'
        }, {
          value: 'CASH_IN_BANK_CURRENT_ACCOUNT',
          description: 'Cash in Bank (Current Account)'
        }, {
          value: 'CASH_IN_BANK_FIXED_DEPOSIT',
          description: 'Cash in Bank (Fixed Deposit)'
        }, {
          value: 'CASH_IN_BANK_MONEY_MARKET_ACCOUNT',
          description: 'Cash in Bank (Money Market Account)'
        }, {
          value: 'CASH_IN_BANK_SAVINGS_ACCOUNT',
          description: 'Cash in Bank (Savings Account)'
        }, {
          value: 'CASH_IN_BANK_HAJJ_ACCOUNT',
          description: 'Cash in Bank (Hajj Account)'
        }, {
          value: 'OTHER_CASH_ASSET_TYPE',
          description: 'Cash in Bank (Any Other Account Type)'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'exchangeRate',
      'currencies',
      'cashAssets'
    ]),
    calculatedLocalAmount () {
      if (Object.keys(this.cash.currency).length !== 0 && this.cash.amount) {
        const index = this.exchangeRate.findIndex(x => x.currencyID === this.cash.currency.currencyID)
        const rate = this.exchangeRate[index].rate
        if (typeof rate !== 'undefined') {
          return (parseFloat((rate) * this.cash.amount)).toFixed(2)
        } else {
          return (parseFloat((1 / 1) * this.cash.amount)).toFixed(2)
        }
      }
      return (0).toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'addToMoney',
      'editMoney',
      'deleteMoneyItem',
      'clearMoney'
    ]),
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    showDelete (key, val) {
      this.cash = val
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.cash = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deleteMoneyItem(this.currentKey)
      this.cash = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.cash = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.cashTypes.forEach((item) => {
        if (item.value === val.cashAssetType) {
          this.cash.type = item
        }
      })
      this.cash.currency = val.currency
      this.cash.description = val.description
      this.cash.amount = val.amount
      this.currentKey = key

      this.currentKey = key
    },
    async update () {
      this.cash.total = parseFloat(this.calculatedAmount)
      const saveCash = {
        key: '',
        item: {
          description: '',
          estimatedRandValue: 0,
          amount: 0,
          cashAssetType: '',
          currency: {}
        }
      }
      saveCash.item.description = this.cash.description
      saveCash.item.estimatedRandValue = this.cash.total
      saveCash.item.amount = this.cash.amount
      saveCash.item.cashAssetType = this.cash.type.value
      saveCash.item.currency = this.cash.currency
      saveCash.key = this.currentKey
      this.editMoney(saveCash)
      this.cash = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      this.cash.total = parseFloat(this.calculatedLocalAmount)
      const saveCash = {
        description: '',
        estimatedRandValue: 0,
        amount: 0,
        cashAssetType: '',
        currency: {}
      }
      saveCash.description = this.cash.description
      saveCash.estimatedRandValue = this.cash.total
      saveCash.amount = this.cash.amount
      saveCash.cashAssetType = this.cash.type.value
      saveCash.currency = this.cash.currency
      this.addToMoney(saveCash)
      this.cash = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
